export const NAVIGATION_ROUTE = {
  HOME: {
    HREF: "/",
  },
  LANDING_DETAIL: {
    HREF: "/detail",
    PATHNAME: "/detail/[eventId]",
  },
  EVENT_LIST: {
    HREF: "/eventList",
  },
  EVENT_DETAIL: {
    HREF: "/event",
    PATHNAME: "/event/[eventId]",
  },
  POST_EVENT: {
    HREF: "/post",
  },
  EDIT_EVENT: {
    HREF: "/post",
    PATHNAME: "/post/[eventId]",
  },
  MY_PAGE: {
    HREF: "/myPage?type=upcoming",
  },
  MY_PAGE_SETTING: {
    HREF: "/myPage/setting",
  },
  MY_PAGE_MANAGING_ACCOUNT: {
    HREF: "/myPage/setting/account",
  },
  EDIT_PROFILE: {
    HREF: "/myPage/profile/edit",
  },
  MY_PAGE_REVIEW: {
    HREF: "/myPage/review",
  },
  AUTH_ENTRY: {
    HREF: "/auth/entry",
  },
  AUTH_LOGIN: {
    HREF: "/auth/login",
  },
  AUTH_SIGNUP: {
    HREF: "/auth/signup",
  },
  AUTH_COMPLETE: {
    HREF: "/auth/signup/complete",
  },
  AUTH_ACTIVATE: {
    HREF: "/auth/activate",
  },
  PROFILE: {
    HREF: "/profile",
    PATHNAME: "/profile/[profileId]",
    QUERY: { type: "joining" },
  },
  CHAT_ROOM_LIST: {
    HREF: "/chat/roomList",
  },
  CHAT_ROOM: {
    HREF: "/chat/room",
  },
  NOTIFICATION: {
    HREF: "/notification",
  },
  PROJECT_DETAIL: {
    HREF: "/project",
    PATHNAME: "/project/[projectId]",
  },
  STAY_INTRODUCTION: {
    HREF: "/stayIntroduction",
  },
  SPARE: {
    HREF: "/spare",
  },
  ADMIN_LOGIN: {
    HREF: "/admin/login",
  },
  ADMIN_PROJECT_LIST: {
    HREF: "/admin/project",
  },
  ADMIN_PROJECT_CREATE: {
    HREF: "/admin/project/create",
  },
  ADMIN_PROJECT_UPDATE: {
    HREF: "/admin/project",
    PATHNAME: "/admin/project/[projectId]/edit",
  },
  ADMIN_POST_PROJECT_EVENT: {
    HREF: "/admin/post/project-event",
  },
} as const;
